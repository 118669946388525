
export enum eRFeatures {
  fh = 'Fleet Health',
  stat = 'Statistics',
  users = 'Users',
  insp = 'Inspections',
  rh = 'Request History',
  diagnostics = 'Diagnostics',
  rep = 'Repairs',
  sr = 'Service Records',
  est = 'Estimate',
  rem = 'Reminders',
  cal = 'Calendar Reminder',
  ts = 'Time Sheet',
  inv = 'Invoice/Service/Repair History',
  vid = 'Video Training Library',
  mv = 'Manage Vehicles',
  it = 'Pre/Post-Trip Inspection Titles',
  dt = 'Diagnostics Titles',
  comp = 'Companies',
  tut = 'Tutorials',
  remp = 'Reminder Profiles',
  sub = 'Subscriptions',
  gps = 'GPS/OBD2',
  obd = 'OBD2 Devices',
  help = 'Call for help'
}
