import { Injectable } from '@angular/core';
import { iRUser } from '../models/fb/user-details.interface';
import { eRRoles as RaRole, eRRoles } from "../enums/eRRoles";
import { eRFeatures } from '../enums/er-features';
import { Subject } from 'rxjs';
import { iRFUser } from '../models/fb/fb-query.interface';

// Company Interface 
export interface iCompany {
  companyName: string;
  id: string;
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLoggedIn = false;
  userDetails?: iRUser;
  userId?: string;
  selectedRole?: RaRole;
  isOverlay = false;
  userCompany?: iCompany;
  chatUserSelected = new Subject<iRFUser>();
  chatSelection$ = this.chatUserSelected.asObservable();

  clear() {
    this.isLoggedIn = false;
    this.userDetails = undefined;
    this.userId = undefined;
    this.selectedRole = undefined;
    this.isOverlay = false;
  }

  get isSelMa() {
    return this.selectedRole === eRRoles.manager;
  }
  get isSelOw() {
    return this.selectedRole === eRRoles.owner;
  }
  get isSelMe() {
    return this.selectedRole === eRRoles.mechanic;
  }
  get isSelUs() {
    return this.selectedRole === eRRoles.user;
  }
  get isSelAd() {
    return this.selectedRole === eRRoles.admin;
  }
  get isMa() {
    return this.userDetails?.role === eRRoles.manager;
  }
  get isOw() {
    return this.userDetails?.role === eRRoles.owner;
  }
  get isMe() {
    return this.userDetails?.role === eRRoles.mechanic;
  }
  get isUs() {
    return this.userDetails?.role === eRRoles.user;
  }
  get isAd() {
    return this.userDetails?.role === eRRoles.admin || this.userDetails?.admin;
  }

  showOverlay() {
    this.isOverlay = true;
  }
  hideOverlay() {
    this.isOverlay = false;
  }

  features = [
    { name: eRFeatures.fh, image: 'assets/features/fleet_health.png', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.stat, image: 'assets/features/statistics.png', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.users, image: 'assets/features/users.png', roles: [RaRole.manager, RaRole.owner, RaRole.admin] },
    { name: eRFeatures.insp, image: 'assets/features/trip-inspection.png', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic, RaRole.user] },
    { name: eRFeatures.rh, image: 'assets/features/request-history.png', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic, RaRole.user] },
    { name: eRFeatures.diagnostics, image: 'assets/features/diagnostic-tile.png', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic] },
    { name: eRFeatures.rep, image: 'assets/features/repairs.png', roles: [RaRole.mechanic] },
    { name: eRFeatures.sr, image: 'assets/features/service-records.png', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic] },
    { name: eRFeatures.est, image: 'assets/features/estimate.png', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic, RaRole.user] },
    { name: eRFeatures.rem, image: 'assets/features/reminders.png', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic] },
    { name: eRFeatures.cal, image: 'assets/features/calendar-reminder.png', roles: [RaRole.mechanic] },
    { name: eRFeatures.ts, image: 'assets/features/timesheet.png', roles: [RaRole.manager, RaRole.owner, RaRole.user] },
    { name: eRFeatures.inv, image: 'assets/features/service-records.png', roles: [RaRole.user] },
    { name: eRFeatures.vid, image: 'assets/features/video-training.png', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic, RaRole.user] },
    { name: eRFeatures.mv, image: 'assets/features/manage-vehicles.png', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.it, image: 'assets/features/pre-post-inspection-titles.png', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.dt, image: 'assets/features/trip-tiles.png', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.comp, image: 'assets/features/companies.png', roles: [RaRole.admin] },
    { name: eRFeatures.tut, image: 'assets/features/tutorials.png', roles: [RaRole.admin] },
    { name: eRFeatures.remp, image: 'assets/features/reminder-profiles.png', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic] },
    { name: eRFeatures.sub, image: 'assets/features/subscriptions.png', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.gps, image: 'assets/features/gps.png', roles: [RaRole.manager, RaRole.owner, RaRole.mechanic] },
    { name: eRFeatures.obd, image: 'assets/features/obd2.png', roles: [RaRole.manager, RaRole.owner] },
    { name: eRFeatures.help, image: 'assets/features/call-for-help.png', roles: [RaRole.user] },
  ];
}
